import { useEffect, useState } from "react";
import { GridList, GridListItem } from "react-aria-components";
import { useIntl } from "react-intl";
import { Navigate } from "react-router-dom";

import { FormButton, IndeterminateProgressBar } from "../../../base";
import { AuthenticationRoute } from "../../../modules/authentication";
import { schedulerClientTypes } from "../../../modules/schedulerClient";
import { AppointmentGroup } from "../appointmentGroup.enum";
import { ITEMS_PER_PAGE, QUERY_PARAMS_BY_APPOINTMENT_GROUP } from "../constants";
import { AppointmentsQueryParams, useAppointments } from "../hooks/useAppointments";
import { AppointmentData } from "../types";
import "./AppointmentList.scss";
import { AppointmentListItem } from "./AppointmentListItem";

export interface AppointmentListProps {
    appointmentGroup: AppointmentGroup;
}

export function AppointmentList(props: AppointmentListProps): React.ReactElement {
    const intl = useIntl();

    const [queryParams, setQueryParams] = useState<AppointmentsQueryParams>({
        ...QUERY_PARAMS_BY_APPOINTMENT_GROUP[props.appointmentGroup],
        limit: ITEMS_PER_PAGE,
    });

    const { appointments: newAppointments, hasMore, isLoading, error } = useAppointments(queryParams);

    const [renderableAppointments, setRenderableAppointments] = useState<AppointmentData[]>([]);

    useEffect(() => {
        setRenderableAppointments((renderableAppointments) => [
            ...renderableAppointments.filter(
                (appointment) =>
                    newAppointments.findIndex((newAppointment) => {
                        return newAppointment.id === appointment.id;
                    }) < 0,
            ),
            ...newAppointments,
        ]);
    }, [newAppointments]);

    const entriesArray = renderableAppointments.map((appointment) => {
        return (
            <GridListItem
                key={appointment.id}
                className="vm_AppointmentList-item"
                textValue={intl.formatMessage(
                    {
                        id: "appointments.appointmentTextValue",
                        defaultMessage: "start time:{startTime}, end time:{endTime}, subject:{subject}",
                    },
                    {
                        startTime: intl.formatDate(appointment.startTime, {
                            hour: "numeric",
                            minute: "numeric",
                            day: "numeric",
                            month: "long",
                            weekday: "short",
                            year: "numeric",
                        }),
                        endTime: intl.formatDate(appointment.endTime, {
                            hour: "numeric",
                            minute: "numeric",
                            day: "numeric",
                            month: "long",
                            weekday: "short",
                            year: "numeric",
                        }),
                        subject: appointment.subject,
                    },
                )}
            >
                <AppointmentListItem appointmentType={props.appointmentGroup} appointmentData={appointment} />
            </GridListItem>
        );
    });

    function loadMoreEntries(): void {
        if (hasMore) {
            setQueryParams({
                ...queryParams,
                offset: renderableAppointments.length,
            });
        }
    }

    function refreshListData(): void {
        setQueryParams({
            ...queryParams,
            offset: 0,
            limit: ITEMS_PER_PAGE,
        });
        setRenderableAppointments([]);
    }

    if (isLoading) {
        return (
            <IndeterminateProgressBar
                label={intl.formatMessage({
                    id: "appointments.loadingAppointments",
                    defaultMessage: "The appointments are loading.",
                })}
            />
        );
    } else if (error !== null) {
        // TODO: handle this on a more general way in SchedulerClient
        if (schedulerClientTypes.isStatusError(error)) {
            if (error.cause.status == 401) {
                return <Navigate to={AuthenticationRoute.LoginPage} replace />;
            }
        }
        return (
            <div className="vm_AppointmentList-error-container">
                <span className="vm_AppointmentList-error">
                    {intl.formatMessage({
                        id: "appointments.fetchError",
                        defaultMessage: "Something went wrong while fetching the appointments.",
                    })}
                </span>
            </div>
        );
    } else {
        return (
            <div>
                <GridList
                    className="vm_AppointmentList"
                    aria-label={intl.formatMessage({
                        id: "appointments.listOfAppointments",
                        defaultMessage: "list of appointments",
                    })}
                    renderEmptyState={() =>
                        intl.formatMessage({
                            id: "appointments.noAppointments",
                            defaultMessage: "There are no scheduled appointments.",
                        })
                    }
                >
                    {entriesArray}
                </GridList>
                <div className="vm_AppointmentList-buttons-container">
                    {hasMore && (
                        <FormButton
                            onPress={loadMoreEntries}
                            text={intl.formatMessage({
                                id: "common.loadMore",
                                defaultMessage: "Load more",
                            })}
                        />
                    )}
                    <FormButton
                        onPress={refreshListData}
                        text={intl.formatMessage({
                            id: "common.refresh",
                            defaultMessage: "Refresh",
                        })}
                    />
                </div>
            </div>
        );
    }
}
