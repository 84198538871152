export interface AppointmentData {
    id: string;
    subject: string;
    startTime: Date;
    endTime: Date;
    creationTime?: Date;
    options?: AppointmentOptions;
}

export interface AppointmentOptions {
    allowGuests: boolean;
    disableChat?: boolean;
    disablePolls?: boolean;
    disableReactions?: boolean;
    requireListed: boolean;
    requireAuthenticated: boolean;
    sendGuestsToLobby: boolean;
    sendUsersToLobby: boolean;
    startE2EEncrypted?: boolean;
    joinAllMuted?: boolean;
    joinAllCamerasOff?: boolean;
    password?: string;
}

export enum JoinPermissionState {
    All,
    Listed,
    Authenticated,
    AuthenticatedAndListed,
}
export enum StartLobbyState {
    All,
    Guests,
    Nobody,
}

export interface AppointmentDataResponse {
    id: string;
    Subject: string;
    startTime: string;
    endTime: string;
    creationTime?: string;
    options?: AppointmentOptions;
}
