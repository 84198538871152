import { RouterProvider } from "react-router-dom";

import "./App.scss";
import { authConstants, useAuth } from "./modules/authentication";
import { SchedulerClient, SchedulerClientContext } from "./modules/schedulerClient";
import { router } from "./router";

const { AuthContext } = authConstants;

function App(): React.ReactElement {
    const authData = useAuth();

    return (
        <div className="vm_App">
            <AuthContext.Provider value={authData}>
                <SchedulerClientContext.Provider
                    value={new SchedulerClient(window.origin, authData.appointmentUserToken)}
                >
                    <RouterProvider router={router} />
                </SchedulerClientContext.Provider>
            </AuthContext.Provider>
        </div>
    );
}

export default App;
