import { useEffect, useState } from "react";
import { GridList, GridListItem } from "react-aria-components";
import { useIntl } from "react-intl";
import { Navigate } from "react-router-dom";

import { FormButton, IndeterminateProgressBar } from "../../../base";
import { AuthenticationRoute } from "../../../modules/authentication";
import { schedulerClientTypes } from "../../../modules/schedulerClient";
import { ITEMS_PER_PAGE } from "../constants";
import { CallLogQueryParams, useCallLogEntries } from "../hooks/useCallLogEntries";
import { CallLogData } from "../types";
import "./CallLogList.scss";
import { CallLogListItem } from "./CallLogListItem";

export function CallLogList(): React.ReactElement {
    const intl = useIntl();

    const [queryParams, setQueryParams] = useState<CallLogQueryParams>({
        limit: ITEMS_PER_PAGE,
    });

    const { callLogEntries, hasMore, isLoading, error } = useCallLogEntries(queryParams);

    const [renderableCalls, setRenderableCalls] = useState<CallLogData[]>([]);

    useEffect(() => {
        setRenderableCalls((renderableCalls) => [
            ...renderableCalls.filter(
                (call) =>
                    callLogEntries.findIndex((newCall) => {
                        return newCall.callId === call.callId;
                    }) < 0,
            ),
            ...callLogEntries,
        ]);
    }, [callLogEntries]);

    const entriesArray = renderableCalls.map((callLogEntry) => {
        return (
            <GridListItem
                key={callLogEntry.callId}
                className="vm_CallLogList-item"
                textValue={intl.formatMessage(
                    {
                        id: "callLog.callLogTextValue",
                        defaultMessage: "start time:{startTime}, end time:{endTime}, type:{type}",
                    },
                    {
                        startTime: intl.formatDate(callLogEntry.startTime, {
                            hour: "numeric",
                            minute: "numeric",
                            day: "numeric",
                            month: "long",
                            weekday: "short",
                            year: "numeric",
                        }),
                        endTime: intl.formatDate(callLogEntry.endTime, {
                            hour: "numeric",
                            minute: "numeric",
                            day: "numeric",
                            month: "long",
                            weekday: "short",
                            year: "numeric",
                        }),
                        type: callLogEntry.appID !== "" ? "matrix" : "appointment",
                    },
                )}
            >
                <CallLogListItem callLogData={callLogEntry} />
            </GridListItem>
        );
    });

    function loadMoreEntries(): void {
        if (hasMore) {
            setQueryParams({
                ...queryParams,
                offset: renderableCalls.length,
            });
        }
    }

    function refreshListData(): void {
        setQueryParams({
            ...queryParams,
            offset: 0,
            limit: ITEMS_PER_PAGE,
        });
        setRenderableCalls([]);
    }

    if (isLoading) {
        return (
            <IndeterminateProgressBar
                label={intl.formatMessage({
                    id: "callLog.loadingLogEntries",
                    defaultMessage: "The call entries are loading.",
                })}
            />
        );
    } else if (error !== null) {
        // TODO: handle this on a more general way in SchedulerClient
        if (schedulerClientTypes.isStatusError(error)) {
            if (error.cause.status == 401) {
                return <Navigate to={AuthenticationRoute.LoginPage} replace />;
            }
        }
        return (
            <div className="vm_CallLogList-error-container">
                <span className="vm_CallLogList-error">
                    {intl.formatMessage({
                        id: "callLog.fetchError",
                        defaultMessage: "Something went wrong while fetching the call entries.",
                    })}
                </span>
            </div>
        );
    } else {
        return (
            <div>
                <GridList
                    className="vm_CallLogLists"
                    aria-label={intl.formatMessage({
                        id: "callLog.listOfCalls",
                        defaultMessage: "list of calls",
                    })}
                    renderEmptyState={() =>
                        intl.formatMessage({
                            id: "callLog.noCalls",
                            defaultMessage: "There are no calls recorded.",
                        })
                    }
                >
                    {entriesArray}
                </GridList>
                <div className="vm_CallLogList-buttons-container">
                    {hasMore && (
                        <FormButton
                            onPress={loadMoreEntries}
                            text={intl.formatMessage({
                                id: "common.loadMore",
                                defaultMessage: "Load more",
                            })}
                        />
                    )}
                    <FormButton
                        onPress={refreshListData}
                        text={intl.formatMessage({
                            id: "common.refresh",
                            defaultMessage: "Refresh",
                        })}
                    />
                </div>
            </div>
        );
    }
}
