import { Route } from "react-router-dom";

import { NavigatableLayout } from "../../layouts/NavigatableLayout";
import { ProtectedRoute } from "../../modules/routing";
import { MainPage } from "./components/MainPage";
import { MainPageRoute } from "./mainPageRoute.enum";

export const MAIN_PAGE_ROUTES = (
    <Route
        path={MainPageRoute.MainPage}
        element={
            <ProtectedRoute>
                <NavigatableLayout />
            </ProtectedRoute>
        }
    >
        <Route path="/" element={<MainPage />} />
    </Route>
);
