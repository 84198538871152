import React from "react";
import { useIntl } from "react-intl";

import { useBrandingConfig } from "../modules/branding";
import { CustomLink } from "./CustomLink";
import "./Footer.scss";

export function Footer(): React.ReactElement {
    const intl = useIntl();

    const brandingConfig = useBrandingConfig();

    return (
        <footer className="vm_Footer">
            <span>{brandingConfig?.brandName}</span>
            <CustomLink
                href={brandingConfig?.privacyUrl || ""}
                target="_blank"
                label={intl.formatMessage({ id: "common.dataPrivacy", defaultMessage: "Data privacy" })}
            />
            <CustomLink
                href={brandingConfig?.imprintUrl || ""}
                target="_blank"
                label={intl.formatMessage({ id: "common.imprint", defaultMessage: "Imprint" })}
            />
            <CustomLink
                href={brandingConfig?.termsOfServiceUrl || ""}
                target="_blank"
                label={intl.formatMessage({ id: "common.termsOfService", defaultMessage: "Terms of Service" })}
            />
        </footer>
    );
}
