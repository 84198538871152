import { appointmentTypes } from "../../modules/appointments";
import { SchedulerClient } from "./SchedulerClient";
import { RequestMethod } from "./requestMethods.enum";

export async function createAppointment(
    schedulerClient: SchedulerClient,
    appointment: Partial<appointmentTypes.AppointmentData>,
): Promise<appointmentTypes.AppointmentData> {
    return schedulerClient
        .sendRequest<appointmentTypes.AppointmentData>({
            url: "/api/v1.1/appointment",
            method: RequestMethod.Post,
            body: appointment,
        })
        .then((resp) => {
            return resp.data;
        });
}

export async function updateAppointment(
    schedulerClient: SchedulerClient,
    appointment: Partial<appointmentTypes.AppointmentData>,
): Promise<appointmentTypes.AppointmentData> {
    return schedulerClient
        .sendRequest<appointmentTypes.AppointmentData>({
            url: "/api/v1.1/appointment/" + appointment.id,
            method: RequestMethod.Post,
            body: appointment,
        })
        .then((resp) => {
            return resp.data;
        });
}

export async function deleteAppointment(
    schedulerClient: SchedulerClient,
    appointmentID: string,
): Promise<appointmentTypes.AppointmentData> {
    return schedulerClient
        .sendRequest<appointmentTypes.AppointmentData>({
            url: "/api/v1.1/appointment/" + appointmentID,
            method: RequestMethod.Delete,
        })
        .then((resp) => {
            return resp.data;
        });
}
