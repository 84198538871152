import { Link } from "react-aria-components";
import { Location, useLocation } from "react-router-dom";

import "./NavBar.scss";

export interface NavOption {
    label: string;
    href: string;
}

interface NavBarProps {
    navOptions: NavOption[];
}

export function NavBar(props: NavBarProps): React.ReactElement {
    const location = useLocation();
    return (
        <nav>
            <ul className="vm_NavBar">{generateNavOptions(props.navOptions, location)}</ul>
        </nav>
    );
}

function generateNavOptions(navOptions: NavOption[], location: Location): React.ReactElement[] {
    const optionsList = navOptions.map((navOption) => {
        return (
            <li key={navOption.href}>
                <Link
                    href={navOption.href}
                    className="vm_NavBar-item"
                    // FIXME: Remove once react-aria current works with hash routing
                    aria-current={location.pathname === navOption.href}
                >
                    {navOption.label}
                </Link>
            </li>
        );
    });

    return optionsList;
}
