import { AppointmentOptions, JoinPermissionState } from "../types";

export function resolveWhoMightJoin(appointmentOptions: AppointmentOptions, joinState: JoinPermissionState): void {
    switch (joinState) {
        case JoinPermissionState.All:
            // Alle zulassen
            appointmentOptions.requireListed = false;
            appointmentOptions.requireAuthenticated = false;
            appointmentOptions.allowGuests = true;
            break;
        case JoinPermissionState.Authenticated:
            //
            appointmentOptions.requireListed = false;
            appointmentOptions.requireAuthenticated = true;
            appointmentOptions.allowGuests = false;
            break;
        case JoinPermissionState.Listed:
            //
            appointmentOptions.requireListed = true;
            appointmentOptions.requireAuthenticated = false;
            appointmentOptions.allowGuests = false;
            break;
        case JoinPermissionState.AuthenticatedAndListed:
            //
            appointmentOptions.requireListed = true;
            appointmentOptions.requireAuthenticated = true;
            appointmentOptions.allowGuests = false;
            break;
    }
}
export function resolveWhoMightJoinState(appointmentOptions: AppointmentOptions): JoinPermissionState {
    if (
        !appointmentOptions.requireListed &&
        appointmentOptions.requireAuthenticated &&
        !appointmentOptions.allowGuests
    ) {
        return JoinPermissionState.Authenticated;
    }
    if (
        appointmentOptions.requireListed &&
        !appointmentOptions.requireAuthenticated &&
        !appointmentOptions.allowGuests
    ) {
        return JoinPermissionState.Listed;
    }
    if (
        appointmentOptions.requireListed &&
        appointmentOptions.requireAuthenticated &&
        !appointmentOptions.allowGuests
    ) {
        return JoinPermissionState.AuthenticatedAndListed;
    }
    if (
        !appointmentOptions.requireListed &&
        !appointmentOptions.requireAuthenticated &&
        appointmentOptions.allowGuests
    ) {
        return JoinPermissionState.All;
    }

    console.warn("unknown join state. return Nobody", appointmentOptions);
    return JoinPermissionState.All;
}
