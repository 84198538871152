import { defineMessages, useIntl } from "react-intl";

import { CallLogData } from "../types";
import "./CallLogListItem.scss";

export interface CallLogListItemProps {
    callLogData: CallLogData;
}

export function CallLogListItem(props: CallLogListItemProps): React.ReactElement {
    const intl = useIntl();

    const statusMessages = defineMessages({
        hailing: {
            id: "callLog.status.hailing",
            defaultMessage: "hailing",
        },
        ongoing: {
            id: "callLog.status.ongoing",
            defaultMessage: "ongoing",
        },
        ended: {
            id: "callLog.status.ended",
            defaultMessage: "ended",
        },
        missed: {
            id: "callLog.status.missed",
            defaultMessage: "missed",
        },
    });
    const callLogTypeMessages = defineMessages({
        appointment: {
            id: "callLog.type.appointment",
            defaultMessage: "based on an appointment",
        },
        matrix: {
            id: "callLog.type.matrix",
            defaultMessage: "based on matrix",
        },
    });

    let formattedTime = "";
    let formattedDate = "";

    const startDate = props.callLogData.startTime.toDateString();
    const endDate = props.callLogData.endTime.toDateString();

    if (startDate === endDate) {
        formattedTime = intl.formatDateTimeRange(props.callLogData.startTime, props.callLogData.endTime, {
            hour: "numeric",
            minute: "numeric",
        });

        formattedDate = intl.formatDate(props.callLogData.startTime, {
            day: "numeric",
            month: "long",
            weekday: "short",
        });
    } else {
        const tempStartTime = new Date(props.callLogData.startTime.toString());
        const tempEndTime = new Date(props.callLogData.startTime.toString());

        /*
        tempStartTime and tempEndTime are needed due to the way intl.formatDateTimeRange
        works. If the date of the start and end time were to be different, then formatDateTimeRange
        would contain the different dates in it's return string. An easier way to omit the
        date and display only the time range has not yet been discovered by the author.
        */

        tempEndTime.setHours(props.callLogData.endTime.getHours());
        tempEndTime.setMinutes(props.callLogData.endTime.getMinutes());

        formattedTime = intl.formatDateTimeRange(tempStartTime, tempEndTime, {
            hour: "numeric",
            minute: "numeric",
        });

        formattedDate = intl.formatDateTimeRange(props.callLogData.startTime, props.callLogData.endTime, {
            day: "numeric",
            month: "long",
            weekday: "short",
        });
    }

    return (
        <div className="vm_CallLogListItem">
            <div className="vm_CallLogListItem-icon-container">
                {/* TODO: switch this placeholder icon to a real one when an icon pack is established */}
                <div className="vm_CallLogListItem-placeholder-icon">
                    <div
                        className="vm_CallLogListItem-placeholder-icon-content"
                        aria-label={intl.formatMessage({ id: "common.userIcon", defaultMessage: "user icon" })}
                    >
                        ●
                        <br />▼
                    </div>
                </div>
            </div>
            <div className="vm_CallLogListItem-date-container">
                <span className="vm_CallLogListItem-date-container-span">{formattedTime}</span>
                <span className="vm_CallLogListItem-date-container-span">{formattedDate}</span>
            </div>
            <div className="vm_CallLogListItem-info-container">
                <span>
                    {intl.formatMessage(
                        {
                            id: "callLog.callLogMessage",
                            defaultMessage: "Call {callType} with {count} participants (state:{status})",
                        },
                        {
                            callType: intl.formatMessage(
                                props.callLogData.appID === undefined
                                    ? callLogTypeMessages.matrix
                                    : callLogTypeMessages.appointment,
                            ),
                            count: props.callLogData.participants.length,
                            status: intl.formatMessage(statusMessages[props.callLogData.status]),
                        },
                    )}
                </span>
            </div>
        </div>
    );
}
