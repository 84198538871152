import React from "react";
import { useIntl } from "react-intl";
import { useRouteError } from "react-router-dom";

import { FormButton } from "../../../base";
import { logInWithOidc } from "../../../modules/authentication/helper/getOidcLoginUrl";

export function NotAuthorizedPage(): React.ReactElement {
    const intl = useIntl();
    const error = useRouteError();
    console.error(error);

    return (
        <div id="error-page">
            <h1>{intl.formatMessage({ id: "error.notAuthorized.title", defaultMessage: "Not authorized" })}</h1>
            <p>
                {intl.formatMessage({
                    id: "error.notAuthorized.notLoggedIn",
                    defaultMessage: "You are currently not logged in.",
                })}
            </p>
            <FormButton
                onPress={() => logInWithOidc(window.location.hash.substring(2))}
                text={intl.formatMessage({ id: "error.notAuthorized.logIn", defaultMessage: "Log in" })}
            />
        </div>
    );
}
