import { useState } from "react";
import { Dialog, Modal, ModalOverlay } from "react-aria-components";
import { useIntl } from "react-intl";

import "./CookieNotice.scss";
import { FormButton } from "./FormButton";

const LOCAL_STORAGE_COOKIE_POLICY_RECOGNISED_KEY = "vistameet.acceptCookies";

const LOCAL_STORAGE_COOKIE_POLICY_RECOGNISED_VALUE = "accepted";

export function CookieNotice(): React.ReactElement {
    const intl = useIntl();

    const [showCookieNotice, setShowCookieNotice] = useState(
        !(
            localStorage.getItem(LOCAL_STORAGE_COOKIE_POLICY_RECOGNISED_KEY) ===
            LOCAL_STORAGE_COOKIE_POLICY_RECOGNISED_VALUE
        ),
    );

    function handleOkPress(): void {
        localStorage.setItem(LOCAL_STORAGE_COOKIE_POLICY_RECOGNISED_KEY, LOCAL_STORAGE_COOKIE_POLICY_RECOGNISED_VALUE);
        setShowCookieNotice(false);
    }

    return (
        <ModalOverlay className="vm_CookieNotice-modal-overlay">
            <Modal className="vm_CookieNotice-modal" isOpen={showCookieNotice}>
                <Dialog
                    className="vm_CookieNotice-dialog"
                    aria-label={intl.formatMessage({ id: "cookie.overlayLabel", defaultMessage: "Cookie policy" })}
                >
                    <div className="vm_CookieNotice-content">
                        <div className="vm_CookieNotice-content-text-container">
                            {intl.formatMessage({
                                id: "cookie.content",
                                defaultMessage:
                                    "This website uses only strictly necessary cookies. They hold a token for your account and store the information whether you recognised this notice before.",
                            })}
                        </div>
                        <div>
                            <FormButton
                                onPress={handleOkPress}
                                text={intl.formatMessage({ id: "cookie.close", defaultMessage: "Ok" })}
                            />
                        </div>
                    </div>
                </Dialog>
            </Modal>
        </ModalOverlay>
    );
}
