import { Link } from "react-aria-components";

import "./CustomLink.scss";

interface CustomLinkProps {
    label?: string;
    href: string;
    target?: string;
}

export function CustomLink(props: CustomLinkProps): React.ReactElement {
    return (
        <Link className="vm_CustomLink" href={props.href} target={props.target}>
            {props.label}
        </Link>
    );
}
