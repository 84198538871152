import { useIntl } from "react-intl";

import { Card } from "../../../base";
import { useBrandingConfig } from "../../../modules/branding";
import "./LoginCard.scss";
import { LoginForm } from "./LoginForm";

export function LoginCard(): React.ReactElement {
    const intl = useIntl();

    const brandingConfig = useBrandingConfig();

    return (
        <Card additionalClassName="vm_LoginCard">
            <div className="vm_LoginCard-image">
                {brandingConfig?.logoUrl != undefined && (
                    <img className="vm_LoginCard-logo" src={brandingConfig?.logoUrl} alt="brand_logo" />
                )}
            </div>
            <h2 className="vm_LoginCard-heading">
                {intl.formatMessage({ id: "authentication.title", defaultMessage: "Please log in" })}
            </h2>
            <LoginForm />
        </Card>
    );
}
