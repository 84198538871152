export function getCookie(cname: string): string {
    const name = cname + "=";

    const decodedCookies = decodeURIComponent(document.cookie).split(";");

    for (let i = 0; i < decodedCookies.length; i++) {
        let cookie = decodedCookies[i];

        while (cookie.charAt(0) == " ") {
            cookie = cookie.substring(1);
        }

        if (cookie.indexOf(name) == 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }

    return "";
}
