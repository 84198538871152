import { useEffect, useState } from "react";

import { RequestMethod, useSchedulerClientContext } from "../../../modules/schedulerClient";
import { AppointmentData, AppointmentDataResponse } from "../types";

interface UseAppointments {
    appointments: AppointmentData[];
    hasMore: boolean;
    isLoading: boolean;
    error: Error | null;
}

export interface AppointmentsQueryParams {
    historical?: boolean;
    startDate?: Date;
    endDate?: Date;
    offset?: number;
    limit?: number;
}

export const useAppointments = (queryParams: AppointmentsQueryParams): UseAppointments => {
    const schedulerClient = useSchedulerClientContext();

    const [appointments, setAppointments] = useState<AppointmentData[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(false);

    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        setIsLoading(true);
        setHasMore(false);

        schedulerClient
            .sendRequest<AppointmentDataResponse[]>({
                url: "/api/v1.1/appointment",
                method: RequestMethod.Get,
                queryParams: { ...queryParams },
            })
            .then((response) => {
                setIsLoading(false);
                setHasMore(response.more!);
                setAppointments(
                    response.data.map((appointment) => {
                        return {
                            id: appointment.id,
                            subject: appointment.Subject,
                            startTime: new Date(appointment.startTime),
                            endTime: new Date(appointment.endTime),
                            creationTime: new Date(appointment.creationTime!),
                            options: appointment.options,
                        };
                    }),
                );
            })
            .catch((err: Error) => {
                setIsLoading(false);
                setError(err);
            });
    }, [schedulerClient, queryParams]);

    return {
        appointments,
        hasMore,
        isLoading,
        error,
    };
};
