import React from "react";
import { RouterProvider } from "react-aria-components";
import { Outlet, useNavigate } from "react-router-dom";

import { CookieNotice, Footer } from "../base";
import "./RootLayout.scss";

export function RootLayout(): React.ReactElement {
    const navigate = useNavigate();

    return (
        <RouterProvider navigate={navigate}>
            <Outlet />
            <Footer />
            <CookieNotice />
        </RouterProvider>
    );
}
