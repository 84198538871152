import React from "react";
import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";

import App from "./App";
import "./index.scss";
import { getCurrentLocale, loadTranslationsWithRetry } from "./modules/i18n";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const currentLocale = getCurrentLocale();

loadTranslationsWithRetry(currentLocale, 2).then((messages) => {
    root.render(
        <React.StrictMode>
            <IntlProvider locale={currentLocale} messages={messages}>
                <App />
            </IntlProvider>
        </React.StrictMode>,
    );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
