import { useEffect, useState } from "react";

import { RequestMethod, useSchedulerClientContext } from "../../../modules/schedulerClient";
import { CallLogData, CallLogDataResponse } from "../types";

interface UseCallLogEntries {
    callLogEntries: CallLogData[];
    hasMore: boolean;
    isLoading: boolean;
    error: Error | null;
}

export interface CallLogQueryParams {
    historical?: boolean;
    startDate?: Date;
    endDate?: Date;
    offset?: number;
    limit?: number;
}

export const useCallLogEntries = (queryParams: CallLogQueryParams): UseCallLogEntries => {
    const schedulerClient = useSchedulerClientContext();

    const [callLogEntries, setCallLogEntries] = useState<CallLogData[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(false);

    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        setIsLoading(true);

        schedulerClient
            .sendRequest<CallLogDataResponse[]>({
                url: "/api/v1.1/user/callLog",
                method: RequestMethod.Get,
                queryParams: { ...queryParams },
            })
            .then((response) => {
                setIsLoading(false);
                setHasMore(response.more!);
                setCallLogEntries(
                    response.data.map((callentry) => {
                        return {
                            callId: callentry.callId,
                            appID: callentry.appID,
                            matrixRoom: callentry.matrixRoom,
                            participants: callentry.participants,
                            startTime: new Date(callentry.startTime),
                            endTime: new Date(callentry.endTime),
                            status: callentry.status,
                        };
                    }),
                );
            })
            .catch((err: Error) => {
                setIsLoading(false);
                setError(err);
            });
    }, [schedulerClient, queryParams]);

    return {
        callLogEntries,
        hasMore,
        isLoading,
        error,
    };
};
