import { Route, createHashRouter, createRoutesFromElements } from "react-router-dom";

import { RootLayout } from "./layouts/RootLayout";
import { CONFERENCES_PAGE_ROUTES } from "./modules/appointments";
import { LOGIN_PAGE_ROUTES } from "./modules/authentication";
import { CALLLOG_PAGE_ROUTES } from "./modules/calllog";
import { MAIN_PAGE_ROUTES } from "./modules/main-page";
import { ErrorPage } from "./modules/main-page/components/ErrorPage";

export const router = createHashRouter(
    createRoutesFromElements(
        <Route path="/" element={<RootLayout />} errorElement={<ErrorPage />}>
            {MAIN_PAGE_ROUTES}
            {LOGIN_PAGE_ROUTES}
            {CONFERENCES_PAGE_ROUTES}
            {CALLLOG_PAGE_ROUTES}
        </Route>,
    ),
);
