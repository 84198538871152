import React from "react";

import { EditConferenceForm } from "./EditConferenceForm";
import "./EditConferencePage.scss";

export function EditConferencePage(): React.ReactElement {
    return (
        <div className="vm_EditConferencePage">
            <EditConferenceForm />
        </div>
    );
}
