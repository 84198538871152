import { AppointmentGroup } from "./appointmentGroup.enum";
import { AppointmentsQueryParams } from "./hooks/useAppointments";

type QueryParamsByAppointmentGroup = {
    [key in AppointmentGroup]: AppointmentsQueryParams;
};

export const QUERY_PARAMS_BY_APPOINTMENT_GROUP: QueryParamsByAppointmentGroup = {
    [AppointmentGroup.Upcoming]: {
        historical: false,
    },
    [AppointmentGroup.Done]: {
        historical: true,
    },
} as const;

export const ITEMS_PER_PAGE = 10;
