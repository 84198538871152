import { IntlShape, useIntl } from "react-intl";

import { NavBar, NavOption } from "../../../base";
import { AppointmentRoute } from "../../../modules/appointments";
import { CallLogRoute } from "../../../modules/calllog";
import { MainPageRoute } from "../../../modules/main-page";

export function MainNavBar(): React.ReactElement {
    const intl = useIntl();

    function generateNavOptions(intl: IntlShape): NavOption[] {
        return [
            {
                label: intl.formatMessage({ id: "navigation.home", defaultMessage: "Home" }),
                href: MainPageRoute.MainPage,
            },
            {
                label: intl.formatMessage({
                    id: "navigation.scheduledConferences",
                    defaultMessage: "Scheduled Conferences",
                }),
                href: AppointmentRoute.ListConferencesPage,
            },
            {
                label: intl.formatMessage({ id: "navigation.createConference", defaultMessage: "New Conference" }),
                href: AppointmentRoute.CreateConferencePage,
            },
            {
                label: intl.formatMessage({ id: "navigation.callLog", defaultMessage: "Call Log" }),
                href: CallLogRoute.CallLogPage,
            },
        ];
    }

    return <NavBar navOptions={generateNavOptions(intl)} />;
}
