import { Button, PressEvent } from "react-aria-components";

import "./FormButton.scss";

interface FormButtonProps {
    text: string;
    onPress?: ((e: PressEvent) => void) | undefined;
    id?: string;
    isDisabled?: boolean;
    isSecondary?: boolean;
}

export function FormButton(props: FormButtonProps): React.ReactElement {
    return (
        <Button
            className={`vm_FormButton ${props.isSecondary ? "vm_FormButton-secondary" : ""}`}
            id={props.id}
            onPress={props.onPress}
            isDisabled={props.isDisabled}
        >
            {props.text}
        </Button>
    );
}
