import { useIntl } from "react-intl";

import { TabView } from "../../../base";
import { AppointmentGroup } from "../appointmentGroup.enum";
import { AppointmentList } from "./AppointmentList";
import "./AppointmentLists.scss";

export function AppointmentLists(): React.ReactElement {
    const intl = useIntl();

    return (
        <TabView
            additionalClassName="vm_AppointmentLists"
            tabs={[
                {
                    id: AppointmentGroup.Upcoming,
                    tab: {
                        text: intl.formatMessage({ id: "appointments.upcoming", defaultMessage: "Upcoming" }),
                    },
                    panel: {
                        children: <AppointmentList appointmentGroup={AppointmentGroup.Upcoming} />,
                    },
                },
                {
                    id: AppointmentGroup.Done,
                    tab: {
                        text: intl.formatMessage({ id: "appointments.done", defaultMessage: "Done" }),
                    },
                    panel: {
                        children: <AppointmentList appointmentGroup={AppointmentGroup.Done} />,
                    },
                },
            ]}
        />
    );
}
