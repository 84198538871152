import React from "react";
import { Navigate } from "react-router-dom";

import { AuthenticationRoute, useAuth } from "../../modules/authentication";

interface ProtectedRouteProps {
    children: React.ReactElement;
}

export function ProtectedRoute(props: ProtectedRouteProps): React.ReactElement {
    const authData = useAuth();

    if (authData.appointmentUserToken === "") {
        return <Navigate to={AuthenticationRoute.LoginPage} replace />;
    }

    return props.children;
}
