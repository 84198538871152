import React from "react";

import { CreateConferenceForm } from "./CreateConferenceForm";
import "./CreateConferencePage.scss";

export function CreateConferencePage(): React.ReactElement {
    return (
        <div className="vm_CreateConferencePage">
            <CreateConferenceForm />
        </div>
    );
}
