import { RequestMethod } from "./requestMethods.enum";

export interface SchedulerResponse<T = void> {
    success: boolean;
    data: T;
    more?: boolean;
    error?: string;
}

export interface RequestParams {
    url: string;
    method: RequestMethod;
    body?: unknown;
    queryParams?: QueryParams;
    abortSignal?: AbortSignal;
}

interface QueryParams {
    [key: string]: number | boolean | Date | String;
}

export interface StatusError {
    name: string;
    message: string;
    cause: { status: number };
}

export function isStatusError(obj: unknown): obj is StatusError {
    return (
        typeof obj === "object" &&
        obj !== null &&
        "cause" in obj &&
        typeof obj.cause === "object" &&
        obj.cause !== null &&
        "status" in obj.cause &&
        obj.cause.status != null &&
        typeof obj.cause.status === "number"
    );
}
