import { MainCard } from "./MainCard";
import "./MainPage.scss";

export function MainPage(): React.ReactElement {
    return (
        <div className="vm_MainPage">
            <MainCard />
        </div>
    );
}
