import { Route } from "react-router-dom";

import { LoginLayout } from "../../layouts/LoginLayout";
import { AuthenticationRoute } from "./authenticationRoute.enum";
import { LoginPage } from "./components/LoginPage";

export const LOGIN_PAGE_ROUTES = (
    <Route path={AuthenticationRoute.LoginPage} element={<LoginLayout />}>
        <Route path={AuthenticationRoute.LoginPage} element={<LoginPage />} />
    </Route>
);
