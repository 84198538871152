import classNames from "classnames";
import { useIntl } from "react-intl";

import { useBrandingConfig } from "../modules/branding";
import "./Header.scss";

export interface HeaderProps {
    additionalClassName?: string;
}

export function Header({ additionalClassName }: HeaderProps): React.ReactElement {
    const brandingConfig = useBrandingConfig();

    const intl = useIntl();
    const classes = classNames("vm_Header", additionalClassName);

    return (
        <header className={classes}>
            <div className="vm_Header-image-container">
                {brandingConfig?.logoUrl != undefined && (
                    <img className="vm_Header-logo" src={brandingConfig?.logoUrl} alt="brand_logo" />
                )}
            </div>
            {brandingConfig?.subBrandLogoUrl != undefined && (
                <div className="vm_Header-sub-branding">
                    <span>{intl.formatMessage({ id: "header.for", defaultMessage: "For" })}</span>
                    <div className="vm_Header-image-container">
                        <img
                            className="vm_Header-sub-branding-logo"
                            src={brandingConfig?.subBrandLogoUrl}
                            alt="sub_brand_logo"
                        />
                    </div>
                </div>
            )}
        </header>
    );
}
