import { ZonedDateTime } from "@internationalized/date";

export const roundTime = (time: ZonedDateTime, intervalMinutes: number): ZonedDateTime => {
    const timeInMinutes = time.hour * 60 + time.minute;
    const remainder = timeInMinutes % intervalMinutes;
    let roundedMinutes;
    if (remainder < intervalMinutes / 2) {
        roundedMinutes = intervalMinutes * Math.floor(timeInMinutes / intervalMinutes);
    } else {
        roundedMinutes = intervalMinutes * (Math.ceil(timeInMinutes / intervalMinutes) || 1);
    }
    const roundedHours = Math.floor(roundedMinutes / 60);
    roundedMinutes = roundedMinutes % 60;
    return time.set({
        hour: roundedHours,
        minute: roundedMinutes,
    });
};
