import { Label, ProgressBar, type ProgressBarProps } from "react-aria-components";

import "./IndeterminateProgressBar.scss";

interface IndeterminateProgressBarProps extends ProgressBarProps {
    label?: string;
}

export function IndeterminateProgressBar({ label, ...props }: IndeterminateProgressBarProps): React.ReactElement {
    return (
        <ProgressBar className="vm_IndeterminateProgressBar" {...props} isIndeterminate>
            <Label>{label}</Label>
            <div className="vm_IndeterminateProgressBar-bar">
                <div className="vm_IndeterminateProgressBar-fill" />
            </div>
        </ProgressBar>
    );
}
