import { useEffect, useState } from "react";

import { BrandingConfig } from ".";

export const useBrandingConfig = (): BrandingConfig | undefined => {
    const [brandingConfig, setBrandingConfig] = useState<BrandingConfig>();
    //TODO: it would probably be a good idea to contain the branding configuration in a store

    useEffect(() => {
        loadBrandingConfigWithRetry(2).then((branding) => {
            setBrandingConfig(branding);
        });
    }, []);

    return brandingConfig;
};

async function fetchBrandingConfig(): Promise<BrandingConfig | undefined> {
    //TODO: fetch the branding from a config.json file (all configuration data should be stored there)

    return fetch("/api/v1.1/config/branding").then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(`Fetching the branding configuration has returned status: ${response.status}`);
        }
    });
}

async function loadBrandingConfigWithRetry(retryCount: number): Promise<BrandingConfig | undefined> {
    try {
        const res = await fetchBrandingConfig();
        return res;
    } catch (error) {
        if (retryCount > 0) {
            await new Promise((resolve) => {
                setTimeout(resolve, 300);
            });

            return await loadBrandingConfigWithRetry(retryCount - 1);
        } else {
            throw new Error("Retry count for fetching the branding configuration file has exceeded the limit", {
                cause: error,
            });
        }
    }
}
