import { useMemo } from "react";

import { authConstants } from "..";
import { getCookie } from "../../../helpers/cookie.utils";
import { AuthData } from "../types";

export const useAuth = (): AuthData => {
    const appointmentUserToken = useMemo(() => getCookie(authConstants.appointmentUserTokenId), []);

    return {
        appointmentUserToken,
    };
};
