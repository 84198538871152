import { FormButton } from "base";
import { useIntl } from "react-intl";

import { logInWithOidc } from "../helper/getOidcLoginUrl";

export function LoginForm(): React.ReactElement {
    const intl = useIntl();

    return (
        <FormButton
            onPress={() => logInWithOidc("")}
            text={intl.formatMessage({ id: "authentication.logInWithOpenId", defaultMessage: "Sign in" })}
        />
    );
}
