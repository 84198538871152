import { RequestMethod, SchedulerClient } from "../../../modules/schedulerClient";

interface AppointmentJoinToken {
    jwt?: string;
    link?: string;
    token?: string;
}

export async function fetchAppointmentJoinToken(
    schedulerClient: SchedulerClient,
    id: string,
): Promise<AppointmentJoinToken> {
    const encodedId = encodeURIComponent(id);

    const res = schedulerClient.sendRequest<AppointmentJoinToken>({
        url: `/api/v1.1/appointment/${encodedId}/joinToken`,
        method: RequestMethod.Get,
    });

    return (await res).data;
}
