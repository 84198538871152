import classNames from "classnames";
import { ReactNode } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-aria-components";

import "./TabView.scss";

interface TabProps {
    id: string;
    tab: {
        text: string;
    };
    panel: {
        children: ReactNode;
    };
}

interface TabViewProps {
    tabs: TabProps[];
    additionalClassName?: string;
}

export function TabView(props: TabViewProps): React.ReactElement {
    const classes = classNames("vm_TabView", props.additionalClassName);
    return (
        <Tabs className={classes}>
            <TabList className="vm_TabView-TabList">{generateTabs(props.tabs)}</TabList>
            {generatePanels(props.tabs)}
        </Tabs>
    );
}

function generateTabs(tabs: TabProps[]): React.ReactElement[] {
    const tablist = tabs.map((tabItem) => {
        return (
            <Tab key={tabItem.id} className="vm_TabView-Tab" id={tabItem.id}>
                <span className="vm_TabView-Tab-Content">{tabItem.tab.text}</span>
            </Tab>
        );
    });

    return tablist;
}

function generatePanels(tabs: TabProps[]): React.ReactElement[] {
    const panelList = tabs.map((tabItem) => {
        return (
            <TabPanel key={tabItem.id} className="vm_TabView-TabPanel" id={tabItem.id}>
                <div className="vm_TabView-TabPanel-Content">{tabItem.panel.children}</div>
            </TabPanel>
        );
    });

    return panelList;
}
