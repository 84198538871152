import { Outlet } from "react-router-dom";

import { Header } from "../base";
import "./LoginLayout.scss";

export function LoginLayout(): React.ReactElement {
    return (
        <>
            <Header additionalClassName="vm_Header-transparent" />
            <main className="vm_LoginLayout">
                <Outlet />
            </main>
        </>
    );
}
