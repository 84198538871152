import { SupportedLocale } from "./supportedLocales.enum";

export function getCurrentLocale(): SupportedLocale {
    return (localStorage.getItem("vistameet.language") as SupportedLocale) || SupportedLocale.De;
}

async function fetchMessages(locale: SupportedLocale): Promise<Record<string, string> | undefined> {
    return fetch(`/assets/i18n/${locale}.json`).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(`Fetching the translation file has returned status: ${response.status}`);
        }
    });
}

export async function loadTranslationsWithRetry(
    locale: SupportedLocale,
    retryCount: number,
): Promise<Record<string, string> | undefined> {
    try {
        const res = await fetchMessages(locale);
        return res;
    } catch (error) {
        if (retryCount > 0) {
            await new Promise((resolve) => {
                setTimeout(resolve, 300);
            });

            return await loadTranslationsWithRetry(locale, retryCount - 1);
        } else {
            throw new Error("Retry count for fetching the translation file has exceeded the limit", { cause: error });
        }
    }
}
