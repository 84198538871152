import classNames from "classnames";
import React from "react";

import "./Card.scss";

interface CardProps {
    children?: React.ReactElement[];
    additionalClassName?: string;
}

export function Card(props: CardProps): React.ReactElement {
    return <div className={classNames("vm_Card", props.additionalClassName)}>{props.children}</div>;
}
