import { Outlet } from "react-router-dom";

import { Header } from "../base";
import { MainNavBar } from "../modules/routing";
import "./NavigatableLayout.scss";

export function NavigatableLayout(): React.ReactElement {
    return (
        <>
            <Header />
            <div className="vm_NavigatableLayout">
                <MainNavBar />
                <main>
                    <Outlet />
                </main>
            </div>
        </>
    );
}
