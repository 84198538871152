import { Route } from "react-router-dom";

import { NavigatableLayout } from "../../layouts/NavigatableLayout";
import { ProtectedRoute } from "../routing";
import { CallLogRoute } from "./callLogRoute.enum";
import { CallLogListPage } from "./components/CallLogListPage";

export const CALLLOG_PAGE_ROUTES = (
    <Route
        path={CallLogRoute.BaseRoute}
        element={
            <ProtectedRoute>
                <NavigatableLayout />
            </ProtectedRoute>
        }
    >
        <Route path={CallLogRoute.CallLogPage} element={<CallLogListPage />} />
    </Route>
);
