export function joinConference(link?: string): void {
    if (!link) {
        throw new Error("A join link was not provided");
    }

    Object.assign(document.createElement("a"), {
        target: "_blank",
        href: link,
    }).click();
}
