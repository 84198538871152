import { fromDate, getLocalTimeZone, now } from "@internationalized/date";
import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogTrigger,
    Heading,
    Input,
    Label,
    ListBox,
    ListBoxItem,
    Modal,
    ModalOverlay,
    Popover,
    Select,
    SelectValue,
    Text,
    TextField,
} from "react-aria-components";
import { useIntl } from "react-intl";
import { useLoaderData, useNavigate } from "react-router-dom";

import { FormButton, Switch } from "../../../base";
import { appointmentAPI, useSchedulerClientContext } from "../../../modules/schedulerClient";
import { AppointmentRoute } from "../appointmentRoute.enum";
import { resolveLobby, resolveLobbyState } from "../helpers/resolveLobbyState";
import { resolveWhoMightJoin, resolveWhoMightJoinState } from "../helpers/resolveWhoMightJoinState";
import { AppointmentData, AppointmentOptions, JoinPermissionState, StartLobbyState } from "../types";
import "./EditConferenceForm.scss";

export function EditConferenceForm(): React.ReactElement {
    const intl = useIntl();
    const schedulerClient = useSchedulerClientContext();
    const navigate = useNavigate();
    //const { confID } = useParams();
    const data = useLoaderData();
    console.log("krombel: data", data);
    const [successWindowOpen, setSuccessWindowOpen] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const [appData, setAppointmentData] = useState(data as AppointmentData);
    const [error, setError] = useState<string | null>(null);

    const nowCalendarDateTime = now(getLocalTimeZone());

    const [subject, setSubject] = useState(appData.subject);
    const [startTime, setStartTime] = useState(
        fromDate(appData.startTime, getLocalTimeZone()).toString().substring(0, "2001-01-01T00:00".length),
    );
    const [endTime, setEndTime] = useState(
        fromDate(appData.endTime, getLocalTimeZone()).toString().substring(0, "2001-01-01T00:00".length),
    );

    const [disableChat, setDisableChat] = useState(appData.options!.disableChat);
    const [disablePolls, setDisablePolls] = useState(appData.options!.disablePolls);
    const [disableReactions, setDisableReactions] = useState(appData.options!.disableReactions);
    const [startE2EEncrypted, setStartE2EEncrypted] = useState(appData.options!.startE2EEncrypted);
    const [joinAllMuted, setJoinAllMuted] = useState(appData.options!.joinAllMuted);
    const [joinAllCamerasOff, setJoinAllCamerasOff] = useState(appData.options!.joinAllCamerasOff);
    const [password, setPassword] = useState(appData.options!.password);

    const [lobbyState, setLobbyState] = useState<StartLobbyState>(resolveLobbyState(appData.options!));
    const [joinPermissionState, setJoinPermissionState] = useState(resolveWhoMightJoinState(appData.options!));

    async function handleDelete(): Promise<void> {
        await appointmentAPI.deleteAppointment(schedulerClient, appData.id);
        navigate(AppointmentRoute.ListConferencesPage);
    }
    async function handleSubmit(): Promise<void> {
        setError(null);

        const appointmentOptions: AppointmentOptions = {
            allowGuests: true,
            disableChat,
            disablePolls,
            disableReactions,
            requireListed: false,
            requireAuthenticated: false,
            sendGuestsToLobby: false,
            sendUsersToLobby: false,
            startE2EEncrypted,
            joinAllMuted,
            joinAllCamerasOff,
            password,
        };
        resolveWhoMightJoin(appointmentOptions, joinPermissionState);
        resolveLobby(appointmentOptions, lobbyState);
        const newAppointment: AppointmentData = {
            id: appData.id,
            subject,
            startTime: new Date(startTime),
            endTime: new Date(endTime),
            options: appointmentOptions,
        };

        try {
            const resp = await appointmentAPI.updateAppointment(schedulerClient, newAppointment);
            console.log("krombel: edited appointment", resp);
            setAppointmentData(newAppointment);
            setSuccessWindowOpen(true);
        } catch (err) {
            console.log("krombel: error while editing appointment", err);
            setError(
                intl.formatMessage({ id: "appointments.editError", defaultMessage: "Failed to update appointment." }),
            );
        }
    }

    return (
        <div className="vm_EditConferenceForm">
            <h1>
                {intl.formatMessage({
                    id: "appointments.editAppointment",
                    defaultMessage: "Edit Appointment",
                })}
            </h1>
            <TextField type="text" id="subject" value={subject} onChange={(e) => setSubject(e)} isRequired>
                <Label>{intl.formatMessage({ id: "appointments.subject", defaultMessage: "Subject" })}</Label>
                <Input />
            </TextField>
            <TextField
                type="datetime-local"
                id="startTime"
                onChange={(e) => {
                    setStartTime(e);
                    if (endTime < e) {
                        setEndTime(
                            fromDate(new Date(e), getLocalTimeZone())
                                .add({ minutes: 30 })
                                .toString()
                                .substring(0, "2001-01-01T00:00".length),
                        );
                    }
                }}
                isRequired
            >
                <Label>{intl.formatMessage({ id: "appointments.startTime", defaultMessage: "Start Time" })}</Label>
                <Input value={startTime} min={nowCalendarDateTime.toString().substring(0, "2001-01-01T00:00".length)} />
            </TextField>
            <TextField type="datetime-local" id="endTime" onChange={(e) => setEndTime(e)} isRequired>
                <Label>{intl.formatMessage({ id: "appointments.endTime", defaultMessage: "End Time" })}</Label>
                <Input value={endTime} min={startTime} />
            </TextField>
            <Select
                id="joinPermissionState"
                defaultSelectedKey={JoinPermissionState.All.valueOf()}
                onSelectionChange={(e) => setJoinPermissionState(e as JoinPermissionState)}
            >
                <Label>
                    {intl.formatMessage({
                        id: "appointments.joinPermissionState.label",
                        defaultMessage: "Who may attend?",
                    })}
                </Label>
                <Button>
                    <SelectValue />
                    <span aria-hidden="true">▼</span>
                </Button>
                <Popover>
                    <ListBox>
                        <ListBoxItem id={JoinPermissionState.All}>
                            {intl.formatMessage({
                                id: "appointments.joinPermissionState.all",
                                defaultMessage: "All (incl. guests)",
                            })}
                        </ListBoxItem>
                        <ListBoxItem id={JoinPermissionState.Authenticated}>
                            {intl.formatMessage({
                                id: "appointments.joinPermissionState.authenticated",
                                defaultMessage: "Attendees with Account",
                            })}
                        </ListBoxItem>
                        <ListBoxItem id={JoinPermissionState.Listed}>
                            {intl.formatMessage({
                                id: "appointments.joinPermissionState.listed",
                                defaultMessage: "Named invited attendees only",
                            })}
                        </ListBoxItem>
                        <ListBoxItem id={JoinPermissionState.AuthenticatedAndListed}>
                            {intl.formatMessage({
                                id: "appointments.joinPermissionState.authenticatedAndListed",
                                defaultMessage: "Attendees with Account and Invite",
                            })}
                        </ListBoxItem>
                    </ListBox>
                </Popover>
            </Select>
            <Select
                id="lobbyState"
                defaultSelectedKey={lobbyState}
                onSelectionChange={(e) => setLobbyState(e as StartLobbyState)}
            >
                <Label>
                    {intl.formatMessage({
                        id: "appointments.lobbyState.label",
                        defaultMessage: "Who will start in the lobby?",
                    })}
                </Label>
                <Button>
                    <SelectValue />
                    <span aria-hidden="true">▼</span>
                </Button>
                <Popover>
                    <ListBox>
                        <ListBoxItem id={StartLobbyState.Nobody}>
                            {intl.formatMessage({
                                id: "appointments.lobbyState.nobody",
                                defaultMessage: "Nobody (lobby disabled)",
                            })}
                        </ListBoxItem>
                        <ListBoxItem id={StartLobbyState.Guests}>
                            {intl.formatMessage({
                                id: "appointments.lobbyState.guests",
                                defaultMessage: "Only external participants",
                            })}
                        </ListBoxItem>
                        <ListBoxItem id={StartLobbyState.All}>
                            {intl.formatMessage({
                                id: "appointments.lobbyState.all",
                                defaultMessage: "Everyone except moderators",
                            })}
                        </ListBoxItem>
                    </ListBox>
                </Popover>
            </Select>
            <DialogTrigger>
                <Button style={{ width: "25.4rem" }}>
                    {intl.formatMessage({
                        id: "appointments.furtherSettings",
                        defaultMessage: "Further Settings",
                    })}
                    <span aria-hidden="true">▼</span>
                </Button>
                <Popover>
                    <Dialog>
                        <div style={{ background: "white" }} className="flex-col">
                            <Switch
                                id="disableChat"
                                style={{ display: "none" }}
                                isSelected={disableChat}
                                onChange={(isSelected) => setDisableChat(isSelected)}
                            >
                                <div className="indicator" />
                                {intl.formatMessage({ id: "appointments.disableChat", defaultMessage: "Disable Chat" })}
                            </Switch>
                            <Switch
                                id="disablePolls"
                                style={{ display: "none" }}
                                isSelected={disablePolls}
                                onChange={(e) => setDisablePolls(e)}
                            >
                                <div className="indicator" />
                                {intl.formatMessage({
                                    id: "appointments.disablePolls",
                                    defaultMessage: "Disable Polls",
                                })}
                            </Switch>
                            <Switch
                                id="disableReactions"
                                style={{ display: "none" }}
                                isSelected={disableReactions}
                                onChange={(e) => setDisableReactions(e)}
                            >
                                <div className="indicator" />
                                {intl.formatMessage({
                                    id: "appointments.disableReactions",
                                    defaultMessage: "Disable Reactions",
                                })}
                            </Switch>
                            <Switch
                                id="startE2EEncrypted"
                                isSelected={startE2EEncrypted}
                                onChange={(e) => setStartE2EEncrypted(e)}
                            >
                                <div className="indicator" />
                                {intl.formatMessage({
                                    id: "appointments.startE2EEncrypted",
                                    defaultMessage: "Start E2E Encrypted",
                                })}
                            </Switch>
                            <Switch id="joinAllMuted" isSelected={joinAllMuted} onChange={(e) => setJoinAllMuted(e)}>
                                <div className="indicator" />
                                {intl.formatMessage({
                                    id: "appointments.joinAllMuted",
                                    defaultMessage: "Join All Muted",
                                })}
                            </Switch>
                            <Switch
                                id="joinAllCamerasOff"
                                isSelected={joinAllCamerasOff}
                                onChange={(e) => setJoinAllCamerasOff(e)}
                            >
                                <div className="indicator" />
                                {intl.formatMessage({
                                    id: "appointments.joinAllCamerasOff",
                                    defaultMessage: "Join All Cameras Off",
                                })}
                            </Switch>
                            <TextField type="text" id="password" onChange={(e) => setPassword(e)}>
                                <Label>
                                    {intl.formatMessage({ id: "appointments.password", defaultMessage: "Password" })}
                                </Label>
                                <Input value={password} />
                            </TextField>{" "}
                        </div>
                    </Dialog>
                </Popover>
            </DialogTrigger>
            <p>&nbsp;</p>
            <TextField>
                {intl.formatMessage({ id: "hint.note", defaultMessage: "Note" })}:{" "}
                {intl.formatMessage({
                    id: "hint.onlySavedInThisSystem",
                    defaultMessage: "This changes are only applied to this system.",
                })}
                <br />
                {intl.formatMessage({
                    id: "hint.pleaseCommunicateOutside",
                    defaultMessage: "Please communicate these changes to the participants as needed.",
                })}
            </TextField>
            {error && <p className="error">{error}</p>}
            <FormButton
                text={intl.formatMessage({ id: "appointments.save", defaultMessage: "Save" })}
                onPress={handleSubmit}
            />
            <FormButton
                text={intl.formatMessage({ id: "appointments.delete", defaultMessage: "Delete" })}
                onPress={() => setShowDeleteConfirm(true)}
            />

            <ModalOverlay isOpen={successWindowOpen}>
                <Modal className="vm_AppointmentSuccess-modal">
                    <Dialog className="vm_AppointmentSuccess-dialog">
                        <Heading slot="title">
                            {intl.formatMessage({
                                id: "appointments.edited.heading",
                                defaultMessage: "Appointment updated",
                            })}
                        </Heading>
                        <Text>
                            {intl.formatMessage({
                                id: "question.howToProceed",
                                defaultMessage: "How do you want to proceed?",
                            })}
                        </Text>
                        <p>&nbsp;</p>
                        <FormButton
                            text={intl.formatMessage({ id: "appointments.continue.copy", defaultMessage: "Copy Link" })}
                            onPress={() =>
                                navigator.clipboard.writeText(
                                    document.location.origin + "/conference/join/" + appData!.id,
                                )
                            }
                            isSecondary={true}
                        />
                        <FormButton
                            text={intl.formatMessage({
                                id: "appointments.continue.overview",
                                defaultMessage: "Go to Overview",
                            })}
                            onPress={() => navigate(AppointmentRoute.ListConferencesPage)}
                        />
                    </Dialog>
                </Modal>
            </ModalOverlay>
            <ModalOverlay isOpen={showDeleteConfirm}>
                <Modal className="vm_AppointmentSuccess-modal">
                    <Dialog className="vm_AppointmentSuccess-dialog">
                        <Heading slot="title">
                            {intl.formatMessage({
                                id: "appointments.deleteConfirm.heading",
                                defaultMessage: "Delete appointment?",
                            })}
                        </Heading>
                        <Text>
                            {intl.formatMessage({
                                id: "appointments.deleteConfirm.msg",
                                defaultMessage: "Do you really want to delete this appointment?",
                            })}
                        </Text>
                        <p>&nbsp;</p>
                        <FormButton
                            text={intl.formatMessage({
                                id: "question.yes",
                                defaultMessage: "Yes",
                            })}
                            onPress={handleDelete}
                            isSecondary={true}
                        />
                        <FormButton
                            text={intl.formatMessage({
                                id: "question.no",
                                defaultMessage: "No",
                            })}
                            onPress={() => setShowDeleteConfirm(false)}
                        />
                    </Dialog>
                </Modal>
            </ModalOverlay>
        </div>
    );
}
