import { RequestParams, SchedulerResponse } from "./types";

export class SchedulerClient {
    private baseURL: string;
    private token?: string;

    public constructor(baseURL: string, token?: string) {
        this.baseURL = baseURL.endsWith("/") ? baseURL.slice(0, -1) : baseURL;
        this.token = token;
    }

    public async sendRequest<T>(params: RequestParams): Promise<{ data: T; more: boolean }> {
        const headers: HeadersInit = {};

        if (this.token) {
            headers["Authorization"] = `Bearer ${this.token}`;
        }

        if (params.body) {
            headers["Content-Type"] = "application/json";
        }

        let response: Response | null;

        const generatedQueryParams: Record<string, string> = {};

        for (const key in params.queryParams || {}) {
            const value = (params.queryParams || {})[key];
            if (value instanceof Date) {
                generatedQueryParams[key] = value.toISOString();
            } else {
                generatedQueryParams[key] = value?.toString() || "";
            }
        }

        const requestUrl =
            `${this.baseURL}${params.url}` +
            (params.queryParams ? `?${new URLSearchParams(generatedQueryParams)}` : "");

        try {
            response = await fetch(requestUrl, {
                method: params.method,
                headers: headers,
                body: params.body ? JSON.stringify(params.body) : undefined,
                signal: params.abortSignal,
            });
        } catch (error) {
            throw new Error(`${params.method} request to ${requestUrl} failed with error ${error}`, { cause: error });
        }

        if (!response.ok) {
            throw new Error(
                `${params.method} request to ${requestUrl} failed with status ${response.status}: "${response.statusText}"`,
                { cause: { status: response.status } },
            );
        }

        const responseBody = (await response.json()) as SchedulerResponse<T>;

        if (!responseBody.success) {
            throw new Error(`${params.method} request to ${requestUrl} failed with error: "${responseBody.error}"`, {
                cause: { status: response.status },
            });
        }

        return { data: responseBody.data, more: Boolean(responseBody.more) };
    }
}
