import React from "react";

import { LoginCard } from "./LoginCard";
import "./LoginPage.scss";

export function LoginPage(): React.ReactElement {
    return (
        //TODO: Rework/finalize design (this is just a placeholder)
        <div className="vm_LoginPage">
            <LoginCard />
        </div>
    );
}
