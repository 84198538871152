import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { FormButton } from "../../../base";
import { schedulerClientTypes, useSchedulerClientContext } from "../../../modules/schedulerClient";
import { AppointmentGroup } from "../appointmentGroup.enum";
import { fetchAppointmentJoinToken } from "../helpers/fetchAppointmentJoinToken";
import { joinConference } from "../helpers/joinConference";
import { AppointmentData } from "../types";
import "./AppointmentListItem.scss";

export interface AppointmentListItemProps {
    appointmentData: AppointmentData;
    appointmentType: AppointmentGroup;
}

export function AppointmentListItem(props: AppointmentListItemProps): React.ReactElement {
    const intl = useIntl();
    const navigate = useNavigate();

    const schedulerClient = useSchedulerClientContext();

    let formattedTime = "";
    let formattedDate = "";

    const startDate = props.appointmentData.startTime.toDateString();
    const endDate = props.appointmentData.endTime.toDateString();

    if (startDate === endDate) {
        formattedTime = intl.formatDateTimeRange(props.appointmentData.startTime, props.appointmentData.endTime, {
            hour: "numeric",
            minute: "numeric",
        });

        formattedDate = intl.formatDate(props.appointmentData.startTime, {
            day: "numeric",
            month: "long",
            weekday: "short",
        });
    } else {
        const tempStartTime = new Date(props.appointmentData.startTime.toString());
        const tempEndTime = new Date(props.appointmentData.startTime.toString());

        /*
        tempStartTime and tempEndTime are needed due to the way intl.formatDateTimeRange
        works. If the date of the start and end time were to be different, then formatDateTimeRange
        would contain the different dates in it's return string. An easier way to omit the
        date and display only the time range has not yet been discovered by the author.
        */

        tempEndTime.setHours(props.appointmentData.endTime.getHours());
        tempEndTime.setMinutes(props.appointmentData.endTime.getMinutes());

        formattedTime = intl.formatDateTimeRange(tempStartTime, tempEndTime, {
            hour: "numeric",
            minute: "numeric",
        });

        formattedDate = intl.formatDateTimeRange(props.appointmentData.startTime, props.appointmentData.endTime, {
            day: "numeric",
            month: "long",
            weekday: "short",
        });
    }

    function joinAppointment(): void {
        fetchAppointmentJoinToken(schedulerClient, props.appointmentData.id)
            .then((joinToken) => {
                joinConference(joinToken.link);
            })
            .catch((error: schedulerClientTypes.StatusError) => {
                if (error.cause.status === 401) {
                    navigate("/login");
                }
            });
    }

    return (
        <div className="vm_AppointmentListItem">
            <div className="vm_AppointmentListItem-icon-container">
                {/* TODO: switch this placeholder icon to a real one when an icon pack is established */}
                <div className="vm_AppointmentListItem-placeholder-icon">
                    <div
                        className="vm_AppointmentListItem-placeholder-icon-content"
                        aria-label={intl.formatMessage({ id: "common.userIcon", defaultMessage: "user icon" })}
                    >
                        ●
                        <br />▼
                    </div>
                </div>
            </div>
            <div className="vm_AppointmentListItem-date-container">
                <span className="vm_AppointmentListItem-date-container-span">{formattedTime}</span>
                <span className="vm_AppointmentListItem-date-container-span">{formattedDate}</span>
            </div>
            <div className="vm_AppointmentListItem-info-container">
                <span>{props.appointmentData.subject}</span>
            </div>
            <div>
                <FormButton
                    onPress={() =>
                        navigator.clipboard.writeText(
                            document.location.origin + "/conference/join/" + props.appointmentData!.id,
                        )
                    }
                    text="&#x1F4CB;" // copy
                    isSecondary={true}
                />
                <FormButton
                    onPress={() => navigate("/conference/edit/" + props.appointmentData!.id)}
                    text="&#x270E;" // edit
                    isSecondary={true}
                />
                {props.appointmentType === AppointmentGroup.Upcoming && (
                    <FormButton
                        onPress={joinAppointment}
                        text={intl.formatMessage({ id: "appointments.join", defaultMessage: "Join" })}
                    />
                )}
            </div>
        </div>
    );
}
