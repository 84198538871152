import { Route } from "react-router-dom";

import { NavigatableLayout } from "../../layouts/NavigatableLayout";
import { ErrorPage } from "../../modules/main-page/components/ErrorPage";
import { ProtectedRoute } from "../../modules/routing";
import { AppointmentRoute } from "./appointmentRoute.enum";
import { CreateConferencePage } from "./components/CreateConferencePage";
import { EditConferencePage } from "./components/EditConferencePage";
import { ScheduledConferencesPage } from "./components/ScheduledConferencesPage";
import { useAppointment } from "./helpers/fetchAppointment";

export const CONFERENCES_PAGE_ROUTES = (
    <Route
        path={AppointmentRoute.BaseConferencesRoute}
        element={
            <ProtectedRoute>
                <NavigatableLayout />
            </ProtectedRoute>
        }
    >
        <Route path={AppointmentRoute.ListConferencesPage} element={<ScheduledConferencesPage />} />
        <Route path={AppointmentRoute.CreateConferencePage} element={<CreateConferencePage />} />
        <Route
            path={AppointmentRoute.EditConferencePage}
            loader={({ params }) => {
                return useAppointment(params.confID!);
            }}
            element={<EditConferencePage />}
            errorElement={<ErrorPage />}
        />
    </Route>
);
