import { getCookie } from "../../../helpers/cookie.utils";
import { appointmentTypes } from "../../../modules/appointments";
import { authConstants } from "../../../modules/authentication";
import { RequestMethod, SchedulerClient } from "../../../modules/schedulerClient";

export async function useAppointment(appID: string): Promise<appointmentTypes.AppointmentData> {
    const auth = getCookie(authConstants.appointmentUserTokenId);
    const client = new SchedulerClient(window.origin, auth);
    const { data: appointment } = await client.sendRequest<appointmentTypes.AppointmentDataResponse>({
        url: "/api/v1.1/appointment/" + appID,
        method: RequestMethod.Get,
    });
    const { data: options } = await client.sendRequest<appointmentTypes.AppointmentOptions>({
        url: "/api/v1.1/appointment/" + appID + "/options",
        method: RequestMethod.Get,
    });
    return {
        id: appointment.id,
        subject: appointment.Subject,
        startTime: new Date(appointment.startTime),
        endTime: new Date(appointment.endTime),
        creationTime: new Date(appointment.creationTime!),
        options: options,
    };
}
