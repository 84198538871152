import React from "react";
import { useRouteError } from "react-router-dom";

import { schedulerClientTypes } from "../../../modules/schedulerClient";
import { AppointmentNotFoundPage } from "./AppointmentNotFoundPage";
import { NotAuthorizedPage } from "./NotAuthorizedPage";

export function ErrorPage(): React.ReactElement {
    const error = useRouteError();
    console.error(error);

    if (schedulerClientTypes.isStatusError(error)) {
        if (error.cause.status == 401) {
            return <NotAuthorizedPage />;
        } else if (error.cause.status == 404) {
            return <AppointmentNotFoundPage />;
        }
    }

    let statusText;
    if (typeof error === "object" && error !== null && "statusText" in error) {
        statusText = error.statusText;
    }
    let message;
    if (typeof error === "object" && error !== null && "message" in error) {
        message = error.message;
    }
    console.log("status", statusText, "msg", message);
    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{/* statusText || message */}</i>
            </p>
        </div>
    );
}
